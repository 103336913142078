//############## PROD ################
//PROD: aws s3 cp --recursive build/ s3://evolv-website-jshapiro-sbx-diagnostics-test
//PROD: aws cloudfront create-invalidation --distribution-id E2S9G7BNOB69IC --paths "/*"
// npm run build && aws s3 cp --recursive build/ s3://evolv-website-jshapiro-sbx-diagnostics-test && aws cloudfront create-invalidation --distribution-id E2S9G7BNOB69IC --paths "/*"

import './App.css'
import React from 'react'
import * as Survey from 'survey-react'

const MIN_EMAIL_LENGTH = 4

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      isMounted: false,
      index: ''
    }
  }

  handleChange(event) {
    console.log(event.target.value)
    this.setState({ email: event.target.value })
  }

  addClick = () => {
    let email = this.state.email
    if (email.length > MIN_EMAIL_LENGTH) {
      this.handleSubmit()
    }
  }

  handleSubmit = (i) => {
    const url = process.env.REACT_APP_GET_TOKEN_URL
    const email = this.state.email
    const headers = {}
    const final_url = url + `?requester_email=${email}`
    console.log(final_url)
    fetch(final_url, {
      method: 'GET',
      headers: headers,
    }).then((response) => {
      alert(
        'Your request was submitted! Check your email for a confirmation link. Now redirecting to evolv.com'
      )
      // window.location.replace('http://evolv.com')
      console.log(response)
    })
    alert(
      'Your request was submitted! Check your email for a confirmation link. Now redirecting to evolv.com'
    )
    // window.location.replace('http://evolv.com')
  }

  createUI = () => {
    return (
      <>
        <table className="form-table">
          <tbody>
            <tr>
              <td>Email:</td>
              <td className="td-textarea">
                <input
                  placeholder={'username'}
                  className="bullet-textarea"
                  onChange={this.handleChange.bind(this)}
                />
                @evolvtechnology.com
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div
                  type="button"
                  className="bullet-button"
                  onClick={this.addClick.bind(this)}
                >
                  Get Link
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }

  getLoginUI = () => {
    return (
      <div>
        <div className="App">
          <div className="nav-bar-div">
            <span className="left-elem">
              <span className="nav-bar-cell form-header">
                View Scanner PDFs
              </span>
            </span>
          </div>
          {this.createUI()}
        </div>
        <div className="blank-footer" />
      </div>
    )
  }

  getScannerIndex = (token) => {
    return (
      <div>
        <div className="App">
          <div className="nav-bar-div">
            <span className="left-elem">
              <span className="nav-bar-cell form-header">Scanner PDFs</span>
            </span>
          </div>
          <div>Has token {token}</div>
        </div>
        <div className="blank-footer" />
      </div>
    )
  }

  getHabitId = (habitName) => habitName.toLowerCase().split(/\s+/).join('-')

  getScannerIndexData = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    const token = params.token
    if (token) {
      console.log('retrieving')
      const url = process.env.REACT_APP_GET_SCANNER_INDEX_URL + `?token=${token}`
      fetch(url, {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((data) => {
          const items = data.map((item) => {
            return item
          })
          var newState = { index: data, isMounted: true, email: '' }
          this.setState(newState)
        })
    }
  }

  getSurvey = () => {
    return this.state.habits.map((habit) => {
      console.log(habit)
      return {
        type: 'rating',
        name: habit.DISPLAY_NAME.S,
        isRequired: true,
        randomprop: 'randomprop',
        minRateDescription: 'Awful',
        maxRateDescription: 'Great',
      }
    })
  }

  componentDidMount() {
    this.getScannerIndexData()
  }

  sendDataToServer = (survey) => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    const token = params.token
    const data = {}
    const surveyData = survey.data
    data.data_points = {}
    Object.keys(surveyData).forEach((surveyKey) => {
      const habitId = this.getHabitId(surveyKey)
      data.data_points[habitId] = surveyData[surveyKey]
    })
    const url = process.env.REACT_APP_GET_TOKEN_URL
    data.token = token
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
    setTimeout(() => {
      window.location.href = 'https://githabit.com'
    }, 1200)
  }

  getPage = (params) => {
    if (params.token) {
      return this.getScannerIndex(params.token)
    } else {
      return this.getLoginUI()
    }
  }

  render() {
    Survey.StylesManager.applyTheme('modern')
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    return this.getPage(params)
    // return (
    //   <div className="App">
    //     <h2 className="page-title">
    //       {' '}
    //       Rate your tenacity on {params.token}
    //     </h2>
    //     {this.state.isMounted && (
    //       <div className="enclose">
    //         {this.getSurvey()}
    //           onComplete={this.sendDataToServer}
    //         />
    //       </div>
    //     )}
    //     <div className="page-footer"></div>
    //   </div>
    // )
  }
}

export default App
